import React from "react";
import { connect } from "react-redux";
import { LoginState } from "../../../Reducers/Login";
import * as Actions from "../../../Actions/RegistBuilding"
import RegistBuildingMainContent from "../../Organization/RegistBuilding/MainContent";
import * as ProgressActions from "../../../Actions/ProgressDialog";

type State = {
    loginState: LoginState
}

const RegistBuildingMontentContainer = connect(
    (state: State, ownProps) => ({
        ...state.loginState,
    }), 
    {
        onClickRegist: Actions.onClickRegist,
        onChangeName: Actions.onChangeName,
        onChangeOwnerName: Actions.onChangeOwnerName,
        onChangeAddress: Actions.onChangeAddress,
        onChangeTelNum: Actions.onChangeTelNum,
        onChangeAvailableCheck: Actions.onChangeAvailableCheck,
        onComponentDidMount: Actions.onComponentDidMount,
        clearInfo: Actions.clearInfo,
        showProgressDialog: ProgressActions.showProgressDialog,
        closeProgressDialog: ProgressActions.closeProgressDialog,
    }
)(RegistBuildingMainContent);

const RegistBuildingTemplate: React.FC = () => {
    return (
        <RegistBuildingMontentContainer />
    )
};

export default RegistBuildingTemplate;