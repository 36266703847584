import React, { useRef } from "react";
import styles from "./index.module.css";

export interface InfiniteScrollProps {
    children: React.ReactNode,
    onReachEnd: Function,
    hasMore: boolean
}

const InfiniteScroll: React.FC<InfiniteScrollProps> =(props) => {
    const divElement = useRef(null);
    let supressEvent = false;

    const onScroll =(event: React.UIEvent<HTMLDivElement>) => {
        if (!divElement.current) { return };
        if (!props.onReachEnd || supressEvent || !props.hasMore) {
            return
        }
        const element = event.currentTarget;
        const { scrollHeight, scrollTop, clientHeight} = element;
        const threshold = clientHeight / 10;
        if (scrollHeight - scrollTop - threshold < clientHeight) {
            supressEvent = true;
            props.onReachEnd();
            setTimeout(() => {
                supressEvent = false
            }, 300)
        }
    };

    return (
        <div className={styles.inifinite_scroll}
             onScroll={(event) => {onScroll(event)}}>
                <div>
                    {props.children}
                </div>
        </div>
    )
};

export default InfiniteScroll;