import { RegistBuildingState } from "../../Reducers/RegistBuilding";
import { filterError, IInputErrors, InputErrorKeys } from "../Common";

export const RegistBuildingInputErrorKeys = {
    REQUIRE_NAME: "REQUIRE_NAME",
    REQUIRE_OWNER_NAME: "REQUIRE_OWNER_NAME",
    REQUIRE_TEL_NUM: "REQUIRE_TEL_NUM",
}

export const checkRegistBuildingInputValue = (state: RegistBuildingState) => {
    const errors: IInputErrors = {}
    if (state.name.length === 0) {
        errors[RegistBuildingInputErrorKeys.REQUIRE_NAME] = true;
    }
    if (state.ownerName.length === 0) {
        errors[RegistBuildingInputErrorKeys.REQUIRE_OWNER_NAME] = true;
    }
    return errors;
}

export const errorNameTitle = (state: RegistBuildingState) => {
    if (filterError(RegistBuildingInputErrorKeys.REQUIRE_NAME, state.errors)) {
        return "名称は必須入力項目です";
    }
    return "";
}
 
export const errorOwnerNameTitle = (state: RegistBuildingState) => {
  if (filterError(RegistBuildingInputErrorKeys.REQUIRE_OWNER_NAME, state.errors)) {
        return "オーナは必須入力項目です";
    }
    return "";
}

export const errorRegisteResultTitle = (state: RegistBuildingState) => {
    if (filterError(InputErrorKeys.API_ERROR, state.errors)) {
        return "登録に失敗しました。";
    } else if(filterError(InputErrorKeys.ETC_ERROR, state.errors)) {
        return "システムエラーです";
    }
    return "";
}