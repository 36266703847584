import React from "react";
import CheckBox from "../../../Atoms/CheckBox";
import styles from "./index.module.css";

type Props = {
    isChecked: boolean,
    onChangeAvailableCheck: Function,
}

const CheckBoxRegist: React.FC<Props> =(props) => {
    return (
        <div className={styles.regist_checkbox_div}>
            <span>レンタル可能</span>
            <CheckBox setChecked={(checked: boolean) => {props.onChangeAvailableCheck(checked)}} isChecked={props.isChecked} />
        </div>
    )
};

export default CheckBoxRegist;