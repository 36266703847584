import { LoginState } from "../../Reducers/Login";

export interface IInputErrors {
    [key: string]: boolean| string;
}

export const InputErrorKeys = {
    API_ERROR: "API_ERROR",
    ETC_ERROR: "ETC_ERROR"
}

export const filterError = (key: string, errors?: IInputErrors, ) => {
    if (!errors) {
        return false;
    }
    let result = Object.keys(errors).filter( error => {
        return error === key;
    })
    return (result.length > 0);
}