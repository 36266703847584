import {forwardRef} from "react";
import styles from "./index.module.css";

type UserIdProps = {
    onChange: Function
}

const InputUserId = forwardRef<HTMLInputElement, UserIdProps>((props, ref) => {
    return (
        <div className={styles.user_id_div}>
            <span className={styles.user_id_title}>ID</span>
            <input type="text"
                    className={styles.user_id_input}
                    onChange={(event) => {props.onChange(event.target.value)}}
                    ref={ref}
                    placeholder="please input userId"/>
        </div>
    )
});

export default InputUserId;