import TextBlack from "../../../Atoms/Text/Black";
import TextHomeTitle from "../../../Atoms/Text/HomeTitle";
import HomeMenuHeader from "../../../Organisms/HomeMenuHeader";
import { useAppSelector } from "../../../../hooks";
import styles from "./index.module.css";
import { useEffect } from "react";
import { buildingListStatus } from "../../../../Reducers/BuildingList";
import { loginStatus } from "../../../../Reducers/Login";
import * as buildingListError from "../../../../Actions/Error/BuildingListError";
import InfiniteScroll from "../../../Atoms/InfiniteScroll";
import SmallRedButton from "../../../Atoms/Button/SmallRed";
import { showCompletedAlert } from "../../../Molecules/Alert/alert";

interface HomeMainContentProps {
    onClickLogout: Function,
    onComponentDidMount: Function,
    showProgressDialog: Function,
    closeProgressDialog: Function,
    onDeleteBuilding: Function,
    clearList: Function,
    clearDeleteInfoOnly: Function,
}

const HomeMainContent: React.FC<HomeMainContentProps> = (props) => {

    const loginInfo = useAppSelector(loginStatus);
    const buildingList = useAppSelector(buildingListStatus);
    const sessionInfoDescription = () => {
        let localId = loginInfo.sessionInfo?.localId ?? "";
        let email = loginInfo.sessionInfo?.email;
        return  "※ログイン情報: " + email + "(" + localId + ")";
    }

    const componentDidMount = useEffect(() => {
        fetchBuildingList();
    }, []);

    const fetchBuildingList = () => {
        props.showProgressDialog();
        props.onComponentDidMount();
    } 
    
    const hasNotFoundError = buildingListError.errorDataNotfound(buildingList).length > 0;
    const hasResultError = buildingListError.errorBuildingListResultTitle(buildingList).length > 0;
    const hasDeleteError = buildingListError.errorDeleteFailed(buildingList).length > 0;

    if (buildingList.data.length > 0 || hasNotFoundError || hasResultError || hasDeleteError) {
        props.closeProgressDialog();
    }

    if (buildingList.isDeleteSuccess) {
        props.clearDeleteInfoOnly();
        props.closeProgressDialog();
        let alertTitle = "建物の削除";
        let message = buildingList.selectedName + "を削除しました。画面を更新します。"
        showCompletedAlert(alertTitle, message, fetchBuildingList);
    }

    const userName = "株)シナトモ　テスター１";
    const serviceName = "fworld";
    const listCountInfo = "【全件数：" + buildingList.data.length + "件】";

    function tableView() {
        return (
            <div style={{ height: '600px', overflow: 'hidden' }}>
                <InfiniteScroll  onReachEnd={()=> {}} hasMore={false}>
                    <table className={styles.home_table}>
                        <thead className={styles.home_th}>
                        <tr>
                            <th></th>
                            <th>ID</th>
                            <th>建物名</th>
                            <th>オーナ</th>
                            <th>電話番号</th>
                            <th>レンタル可否</th>
                            <th>削除</th>
                        </tr>
                        </thead>
                        <tbody>{buidingsTableInfo()}</tbody>
                    </table> 
                </InfiniteScroll>
            </div>
        )
    } 

    function buidingsTableInfo() {
        if (!buildingList.data) {
            return (<tr><td colSpan={5}><span>データがありません。</span></td></tr>)
        }
        return buildingList.data.map((info, index) => {
            return (
                <tr className={styles.home_tr} key={info.id}>
                    <td>{index + 1}</td>
                    <td>{info.id}</td>
                    <td>{info.name}</td>
                    <td>{info.ownerName}</td>
                    <td>{info.tel}</td>
                    <td>{info.isAvailableRent ? "可" : "不"}</td>
                    <td> <SmallRedButton title={"削除"} disabled={false} onClick= {() => {
                        props.showProgressDialog();
                        setTimeout(() => {
                            props.onDeleteBuilding(info.id, info.name);
                        }, 100)
                    }} /></td>
                </tr>
                )
        })
    }

    return (
        <div>
            <div className={styles.home_title_div}>
                <TextHomeTitle title={"管理画面 - メイン"} />
            </div>
            <div className={styles.home_container}>
                <HomeMenuHeader onClickLogout={props.onClickLogout} userName={userName} serviceName={serviceName}/>
                <div className={styles.sessionInfo_div}>
                    <TextBlack title={listCountInfo + sessionInfoDescription() }/>
                </div>
                {tableView()}
            </div>
        </div>
    );
}

export default HomeMainContent;