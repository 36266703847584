import { store } from './Store/Building';
import { Provider } from 'react-redux';
import LoginTemplate from './components/Templates/Login';
import HomeTemplate from './components/Templates/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RegistBuildingTemplate from './components/Templates/RegistBuilding';
import ProgressDialogTemplate from './components/Templates/ProgressDialog';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<Provider store={store}>
                                      <LoginTemplate />
                                      <ProgressDialogTemplate />
                                    </Provider>}/>
        <Route path={`/home`} element={<Provider store={store}>
                                          <HomeTemplate />
                                          <ProgressDialogTemplate />
                                        </Provider>}/>
        <Route path={`/regist`} element={<Provider store={store}>
                                          <RegistBuildingTemplate />
                                          <ProgressDialogTemplate />
                                        </Provider>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
