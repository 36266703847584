import {Action, Dispatch} from "redux";
import { BuildingRepository } from "../../domain/repository/Building";
import { store } from "../../Store/Building";
import { IInputErrors, InputErrorKeys } from "../Common";
import { checkRegistBuildingInputValue } from "../Error/RegistBuildingError";

export const ActonTypes = {
    ON_CHANGE_NAME: "REGIST_ON_CHANGE_NAME",
    ON_CHANGE_OWNER_NAME: "REGIST_ON_CHANGE_OWNER_NAME",
    ON_CHANGE_ADDRESS: "REGIST_ON_CHANGE_ADDRESS",
    ON_CHANGE_TEL_NUM: "REGIST_ON_CHANGE_TEL_NUM",
    ON_CHANGE_AVAILABLE: "REGIST_ON_CHANGE_AVAILABLE",
    ON_FAIURE_REGIST: "OREGIST_N_FAIURE_REGIST",
    COMPLETE_REGIST: "REGIST_COMPLETE_REGIST",
    ON_COMPONENT_DID_MOUNT: "REGIST_ON_COMPONENT_DID_MOUNT",
    CLEAR_INFO: "REGIST_CLEAR_INFO",
}

export interface IRegisterAction extends Action {
    name: string,
    ownerName: string,
    address: string,
    telNum: string,
    isAvailable: boolean,
    errors: IInputErrors,
}

export const onComponentDidMount = () => ({
    type: ActonTypes.ON_COMPONENT_DID_MOUNT,
});

export const onClickRegist = () => {
    return async(dispatch: Dispatch, getState: typeof store.getState) => {
 
        let loginState = getState().login;
        let state = getState().registBuilding;
        let authToken = loginState.sessionInfo?.idToken ?? "";
        let errors = checkRegistBuildingInputValue(state)
        if (Object.keys(errors).length > 0) {
            dispatch({
                type: ActonTypes.ON_FAIURE_REGIST,
                errors: errors
            })
            return;
        }

        let buildingRepository = new BuildingRepository();
        let response = await buildingRepository.sendBuildingInfo(state.name,
                                                                state.ownerName,
                                                                state.telNum,
                                                                state.isAvailable,
                                                                authToken);
        console.log("onClickRegist response status ==>" + response.status );
        let isReigstSucces = true;
        if (response.error === InputErrorKeys.API_ERROR || response.error === InputErrorKeys.ETC_ERROR) {
            errors[response.error] = true;
            dispatch({
                type: ActonTypes.ON_FAIURE_REGIST,
                errors: errors  
            })
            return;
        }
        if (isReigstSucces) {
            dispatch({
                type: ActonTypes.COMPLETE_REGIST,
            })
            return;
         }
        errors[InputErrorKeys.ETC_ERROR] = true;
        dispatch({
            type: ActonTypes.ON_FAIURE_REGIST,
            errors: errors  
        })
         
    }
}

export const onChangeName = (name: string) => ({
    type: ActonTypes.ON_CHANGE_NAME,
    name: name
});


export const onChangeOwnerName = (ownerName: string) => ({
    type: ActonTypes.ON_CHANGE_OWNER_NAME,
    ownerName: ownerName
});

export const onChangeAddress = (address: string) => ({
    type: ActonTypes.ON_CHANGE_ADDRESS,
    address: address
});

export const onChangeTelNum = (telNum: string) => ({
    type: ActonTypes.ON_CHANGE_TEL_NUM,
    telNum: telNum
});

export const onChangeAvailableCheck = (isAvailable: boolean) => ({
    type: ActonTypes.ON_CHANGE_AVAILABLE,
    isAvailable: isAvailable
});

export const clearInfo = () => ({
    type: ActonTypes.CLEAR_INFO
});
