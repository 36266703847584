import { ProgressDialogStatus } from "../../../Reducers/ProgressDialog";
import {connect} from "react-redux";
import MainContent from "../../Organisms/ProgressDialog/MainContent";

type State = {
    progressDialog: ProgressDialogStatus
}

const ProgressDialogContainer = connect (
    (state: State, ownProps) => ({
        ...state.progressDialog
    }), {
        
    }
)(MainContent);

const ProgressDialogTemplate: React.FC = () => {
    return (
        <ProgressDialogContainer/>
    )
}

export default ProgressDialogTemplate;