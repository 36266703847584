import { IInputErrors } from "../../Actions/Common";
import { ActonTypes, ILoginAction } from "../../Actions/Login";
import { RootState } from "../../Store/Building";

export type LoginState = {
    userId: string,
    password: string,
    isLoginSuccess: boolean,
    errors?: IInputErrors,
    sessionInfo?: SessionInfo
}

export type SessionInfo = {
    kind: string,
    localId: string,
    email: string,
    displayName: string,
    idToken: string,
    registered: boolean,
    refreshToken: string,
    expiresIn: string,
}

const InitialSessionInfo: SessionInfo = {
    kind: "",
    localId: "",
    email: "",
    displayName: "",
    idToken: "",
    registered: false,
    refreshToken: "",
    expiresIn: "",
}

export const InitialState: LoginState = {
    userId: "",
    password: "",
    isLoginSuccess: false,
    sessionInfo: InitialSessionInfo
};

export const loginStatus = (state: RootState) => state.login;

export const Login = (state: LoginState = InitialState, action: ILoginAction) => {
    switch(action.type) {
        case ActonTypes.COMPLETE_LOGIN: {
            state = {
                ...state,
                sessionInfo: action.sessionInfo,
                isLoginSuccess: true,
                errors: {}
            }
            break;
        }
        case ActonTypes.ON_CHANGE_USER_ID: {
            state = {
                ...state,
                userId: action.userId
            }
            break;
        }
        case ActonTypes.ON_CHANGE_PASSWORD: {
            state = {
                ...state,
                password: action.password
            }
            break;
        }
        case ActonTypes.ON_FAIURE_LOGIN: {
            state = {
                ...state,
                errors: action.errors
            }
            break
        }
        case ActonTypes.ON_LOGOUT:{
            state = {
            ...InitialState
            }
            break
        }
        case ActonTypes.ON_COMPONENT_DID_MOUNT: {
            state = {
                ...InitialState
            }
            break
        }
        default: break;
    }
    return state;
}