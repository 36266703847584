import { LoginState } from "../../Reducers/Login";
import { filterError, IInputErrors, InputErrorKeys } from "../Common";

export const LoginInputErrorKeys = {
    REQUIRE_USER_ID: "REQUIRE_USER_ID",
    REQUIRE_PASSWORD: "REQUIRE_PASSWORD",
    MINIMUM_USER_ID_LENGTH: "MINMUM_USER_ID_LENGTH",
    MINIMUM_PASSWORD_LENGTH: "MINMUM_PASSWORD_LENGTH",
}

export const checkLoginInputValue = (state: LoginState) => {
    const maxUserIdLength = 6;
    const maxPasswordLength = 6;
    const errors: IInputErrors = {}
    if (state.userId.length === 0) {
        errors[LoginInputErrorKeys.REQUIRE_USER_ID] = true;
    } else if (state.userId.length < maxUserIdLength) {
        errors[LoginInputErrorKeys.MINIMUM_USER_ID_LENGTH] = true;
    }

    if (state.password.length === 0) {
        errors[LoginInputErrorKeys.REQUIRE_PASSWORD] = true;
    } else if (state.password.length < maxPasswordLength) {
        errors[LoginInputErrorKeys.MINIMUM_PASSWORD_LENGTH] = true;
    }
    return errors;
}

export const errorUserIdTitle = (loginInfo: LoginState) => {
    if (filterError(LoginInputErrorKeys.REQUIRE_USER_ID, loginInfo.errors)) {
        return "ユーザIDは必須入力項目です";
    } else if (filterError(LoginInputErrorKeys.MINIMUM_USER_ID_LENGTH, loginInfo.errors)) {
        return "ユーザIDは6桁以上入力してください";
    } 
    return "";
}

export const errorPasswordTitle = (loginInfo: LoginState) => {

    if (filterError(LoginInputErrorKeys.REQUIRE_PASSWORD, loginInfo.errors)) {
        return "パスワードは必須入力項目です";
    } else if (filterError(LoginInputErrorKeys.MINIMUM_PASSWORD_LENGTH, loginInfo.errors)) {
        return "パスワードは６桁入力してください";
    } else if (filterError(InputErrorKeys.API_ERROR, loginInfo.errors)) {
        return "IDまたはパスワードを確認してください";
    } else if(filterError(InputErrorKeys.ETC_ERROR, loginInfo.errors)) {
        return "システムエラーです";
    }
    return "";
}