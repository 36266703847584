import { BuidingListState } from "../../Reducers/BuildingList";
import { filterError, IInputErrors, InputErrorKeys } from "../Common";

export const BuildingListErrorKeys = {
    BUIDING_DATA_NOT_FOUND: "BUIDING_DATA_NOT_FOUND",
    BUIDING_DELETED_FAILED: "BUIDING_DELETED_FAILED",
}


export const errorDataNotfound = (state: BuidingListState) => {
    if (filterError(BuildingListErrorKeys.BUIDING_DATA_NOT_FOUND, state.errors)) {
        return "建物一覧がありません";
    }
    return "";
}

export const errorDeleteFailed = (state: BuidingListState) => {
    if (filterError(BuildingListErrorKeys.BUIDING_DELETED_FAILED, state.errors)) {
        return "縦物の削除に失敗しました";
    }
    return "";
}

export const errorBuildingListResultTitle = (state: BuidingListState) => {
    if (filterError(InputErrorKeys.API_ERROR, state.errors)) {
        return "建物一覧の取得に失敗しました";
    } else if(filterError(InputErrorKeys.ETC_ERROR, state.errors)) {
        return "システムエラーです";
    }
    return "";
}