import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

export const showCompletedAlert = (title: string, 
                                    message: string,
                                    onSuccess: Function,
                                    okButtonTitle?: string) => {
    confirmAlert({
        title: title,
        message: message,
        buttons: [
        {
          label: okButtonTitle ?? "OK",
          onClick: () => { onSuccess()}
        }
      ]
    });
};

export const showConfirmAlert = (title: string,
                                    message: string,
                                    onSuccess: Function,
                                    onCancel: Function,
                                    okButtonTitle?: string,
                                    cancelButtonTitle?: string) => {
    confirmAlert({
        title: title,
        message: message,
        buttons: [
        {
            label: okButtonTitle ?? "OK",
            onClick: () => { onSuccess()}
        },
        {
            label: cancelButtonTitle ?? "Cancel",
            onClick: () => { onCancel()}
            }
        ]
        });
};