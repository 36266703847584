import {forwardRef} from "react";
import styles from "./index.module.css";

type PasswordProps = {
    onChange: Function
}

const InputPassword = forwardRef<HTMLInputElement, PasswordProps>((props, ref) => {
    return (
        <div className={styles.password_div}>
            <span className={styles.password_title}>PW</span>
            <input type="password"
                    className={styles.password_input}
                    onChange={(event) => {props.onChange(event.target.value)}} 
                    ref={ref}
                    placeholder="please input password"/>
        </div>
    )
});

export default InputPassword;