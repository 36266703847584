import { ActionTypes, IProgressDialogAction } from "../../Actions/ProgressDialog"
import { RootState } from "../../Store/Building";

export type ProgressDialogStatus = {
    isShowing: boolean
}

const InitialState: ProgressDialogStatus = {
    isShowing: false,
}

export const progressStatus = (state: RootState) => state.progressDialog;

export const ProgressDialog = (state: ProgressDialogStatus = InitialState, action: IProgressDialogAction) => {
    switch(action.type) {
        case ActionTypes.REQUST_SHOW: {
            return {
                ...state,
                isShowing: true
            }
        }
        case ActionTypes.REQUST_CLOSE: {
            return {
                ...state,
                isShowing: false
            }
        }
        default: return state;
    }
}