import React from "react";
import styles from "./index.module.css";

type Props = {
    title: string
}

const TextLoginError: React.FC<Props> =(props) => {
    return (
        <span className={styles.login_error}>{props.title}</span>
    )
};

export default TextLoginError;