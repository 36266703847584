import {forwardRef} from "react";
import styles from "./index.module.css";

type RegistProps = {
    title: string,
    onChange: Function
}

const InputRegist = forwardRef<HTMLInputElement, RegistProps>((props, ref) => {
    return (
        <div className={styles.regist_div}>
            <span className={styles.regist_title}>{props.title}</span>
            <input type="text"
                    className={styles.regist_input}
                    onChange={(event) => {props.onChange(event.target.value)}}
                    ref={ref}
                    />
        </div>
    )
});

export default InputRegist;