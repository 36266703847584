import React from "react";
import styles from "./index.module.css";

type Props = {
    setChecked: Function
    isChecked: boolean
}

const CheckBox: React.FC<Props> = (props) => {
    return (
        <span className={styles.check_button_title}>
            <input type="checkbox" defaultChecked={props.isChecked} onChange={(event) => {props.setChecked(event.target.checked)}}/>
        </span>
    )
}

export default CheckBox;