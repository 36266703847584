import { SessionInfo } from "../../Reducers/Login";
import ApiRequest from "../Api";

export interface LoginResponse {
    status: number,
    sessionInfo?: SessionInfo,
    error?: any
}

export class LoginRepository {
    public async getLoginSession(email: string, password: string): Promise<LoginResponse> {
        let key = "key=AIzaSyAjpv9eJmVJTwp1gy4cgmJdkGShzu2iBsI";
        let url = "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?" ;
        let params = {
            email: email,
            password: password,
            returnSecureToken: true
        }
        let response = await new ApiRequest().login(url + key, params);
        return {
            status: response.status, 
            sessionInfo: response.datas as SessionInfo,
            error: response.error
        }
    }
}