import React from "react";
import { connect } from "react-redux";
import { LoginState } from "../../../Reducers/Login";
import * as LoginActions from "../../../Actions/Login";
import * as ProgressActions from "../../../Actions/ProgressDialog";
import LoginMainContent from "../../Organization/Login/MainContent";

type State = {
    loginState: LoginState
}

const LoginContentContainer = connect(
    (state: State, ownProps) => ({
        ...state.loginState,
    }), 
    {
        onClickLogin: LoginActions.onClickLogin,
        onChangeUserId: LoginActions.onChangeUserId,
        onChangePassword: LoginActions.onChangePassword,
        onComponentDidMount: LoginActions.onComponentDidMount,
        showProgressDialog: ProgressActions.showProgressDialog,
        closeProgressDialog: ProgressActions.closeProgressDialog,
    }
)(LoginMainContent);

const LoginTemplate: React.FC = () => {
    return (
        <LoginContentContainer />
    )
};

export default LoginTemplate;