import React from "react";
import { connect } from "react-redux";
import { LoginState } from "../../../Reducers/Login";
import * as LoginActions from "../../../Actions/Login";
import * as BuildListActions from "../../../Actions/BuildingList";
import * as ProgressActions from "../../../Actions/ProgressDialog";
import HomeMainContent from "../../Organization/Home/MainContent";

type State = {
    loginState: LoginState
}

const HomeContentContainer = connect(
    (state: State, ownProps) => ({
        ...state.loginState,
    }), 
    {
        onClickLogout: LoginActions.onClickLogout,
        onComponentDidMount: BuildListActions.onComponentDidMount,
        showProgressDialog: ProgressActions.showProgressDialog,
        closeProgressDialog: ProgressActions.closeProgressDialog,
        clearList: BuildListActions.clearList,
        onDeleteBuilding: BuildListActions.onDeleteBuilding,
        clearDeleteInfoOnly: BuildListActions.clearDeleteInfoOnly,
    }
)(HomeMainContent);

const HomeTemplate: React.FC = () => {
    return (
        <HomeContentContainer />
    )
};

export default HomeTemplate;