import { Action } from "redux";

export enum ActionTypes {
    REQUST_SHOW= "PROGRESS_DIALOG_REQUST_SHOW",
    REQUST_CLOSE= "PROGRESS_DIALOG_REQUST_CLOSE",
}

export interface IProgressDialogAction extends Action {
}

export const showProgressDialog = () =>({
    type: ActionTypes.REQUST_SHOW
});

export const closeProgressDialog = () =>({
    type: ActionTypes.REQUST_CLOSE
});