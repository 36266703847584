import React from "react";
import styles from "./index.module.css";

type Props = {
    onClick: Function
    title: string
}

const ButtonLogin: React.FC<Props> = (props) => {
    return (
        <button className={styles.login_button} onClick={() => {props.onClick()}}>
            <span className={styles.login_button_title}>{props.title}</span>
        </button>
    )
}

export default ButtonLogin;