import { ActonTypes, IRegisterAction } from "../../Actions/RegistBuilding";
import { IInputErrors } from "../../Actions/Common";
import { RootState } from "../../Store/Building";
import { ActionTypes } from "../../Actions/ProgressDialog";

export type RegistBuildingState = {
    name: string,
    ownerName: string,
    address: string,
    telNum: string,
    isAvailable: boolean,
    isRegistSuccess: boolean,
    errors?: IInputErrors
}

export const InitialState: RegistBuildingState = {
    name: "",
    ownerName: "",
    address: "",
    telNum: "",
    isAvailable: false,
    isRegistSuccess: false
};

export const registBuilding = (state: RootState) => state.registBuilding;

export const RegistBuilding = (state: RegistBuildingState = InitialState, action: IRegisterAction) => {
    switch(action.type) {
        case ActonTypes.ON_COMPONENT_DID_MOUNT: {
            state = {
                ...InitialState
            }
            break
        }
        case ActonTypes.COMPLETE_REGIST: {
            state = {
                ...state,
                isRegistSuccess: true,
                errors: {}
            }
            break;
        }
        case ActonTypes.ON_CHANGE_NAME: {
            state = {
                ...state,
                name: action.name
            }
            break;
        }
        case ActonTypes.ON_CHANGE_OWNER_NAME: {
            state = {
                ...state,
                ownerName: action.ownerName
            }
            break;
        }
        case ActonTypes.ON_CHANGE_ADDRESS: {
            state = {
                ...state,
                address: action.address
            }
            break;
        }
        case ActonTypes.ON_CHANGE_TEL_NUM: {
            state = {
                ...state,
                telNum: action.telNum
            }
            break;
        }
        case ActonTypes.ON_CHANGE_AVAILABLE: {
            state = {
                ...state,
                isAvailable: action.isAvailable
            }
            break;
        }
        case ActonTypes.ON_FAIURE_REGIST: {
            state = {
                ...state,
                errors: action.errors
            }
            break
        }
        case ActonTypes.CLEAR_INFO: {
            state = {
                ...InitialState
            }
            break
        }
        default: break;
    }
    return state;
}