import { useEffect, useRef } from "react";
import styles from "./index.module.css";
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useAppSelector } from "../../../../hooks";
import { progressStatus } from "../../../../Reducers/ProgressDialog";

interface MainContentProps {
}

export const MainContent: React.FC<MainContentProps> = (props) => {

    const status = useAppSelector(progressStatus);
    const dialogRef = useRef<HTMLDialogElement>(null);
    const dialogVisiblility = useEffect(() => {
        if (!dialogRef.current) {
            return;
        }
        if (status.isShowing) {
            dialogRef.current.showModal();
        } else {
            dialogRef.current.close();
        }
    }, [status.isShowing]);

    return (
        <dialog ref={dialogRef} className={styles.dialog}>
            <div className={styles.progress_content}>
                <AiOutlineLoading3Quarters size={100} color={"#009FF0"}/>
            </div>
        </dialog>
    );
}

export default MainContent;