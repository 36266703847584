import React, { useEffect, useState } from "react";
import ButtonLogin from "../../../Atoms/Button/Login";
import TextBoldBlack from "../../../Atoms/Text/BoldBlack";
import InputPassword from "../../../Molecules/Input/Password";
import InputUserId from "../../../Molecules/Input/UserId";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import * as loginError from "../../../../Actions/Error/LoginError";
import TextLoginError from "../../../Atoms/Text/LoginError";
import { loginStatus } from "../../../../Reducers/Login";
import { useAppSelector } from "../../../../hooks";

interface LoginMainContentProps {
    onClickLogin: Function,
    onChangeUserId: Function,
    onChangePassword: Function,
    onComponentDidMount: Function,
    showProgressDialog: Function,
    closeProgressDialog: Function
}

const LoginMainContent: React.FC<LoginMainContentProps> = (props) => {

    const loginInfo = useAppSelector(loginStatus);

    let navigate = useNavigate(); 
    const moveToHome = () => { 
        navigate(`/home`);
    }

    if (loginInfo.isLoginSuccess) {
        props.closeProgressDialog();
        moveToHome();
    }

    const hasUserIdError = loginError.errorUserIdTitle(loginInfo).length > 0;
    const hasPasswordError = loginError.errorPasswordTitle(loginInfo).length > 0;

    if (hasUserIdError || hasPasswordError) {
        props.closeProgressDialog();
    }

    const componentDidMount = useEffect(() => {
        props.onComponentDidMount();
    }, []);

    return (
        <div className={styles.login_container}>
            <div className={styles.login_contents}>
                <div className={styles.login_title}>
                    <TextBoldBlack title="fworld" />
                </div>
                <InputUserId onChange={(value: string) => {props.onChangeUserId(value)}}/>
                <div style={{ display: hasUserIdError ? '' : 'none' }}>
                    <TextLoginError title={loginError.errorUserIdTitle(loginInfo)}/>
                </div>
                <InputPassword onChange={(value: string) => {props.onChangePassword(value)}}/>
                <div style={{ display: hasPasswordError ? '' : 'none' }}>
                    <TextLoginError title={loginError.errorPasswordTitle(loginInfo)}/>
                </div>
                <div className={styles.login_button_div}>
                    <ButtonLogin title = {"ログイン"} onClick={() => {
                        props.showProgressDialog();
                        props.onClickLogin();
                    }} />
                </div>
            </div>
        </div>
    );
}

export default LoginMainContent;