import React, { useEffect, useState } from "react";
import MenuButton from "../../Atoms/Button/Menu";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import { loginStatus } from "../../../Reducers/Login";
import { useAppSelector } from "../../../hooks";

interface HomeMenuHeaderProps {
    serviceName: string,
    userName: string,
    onClickLogout: Function,
}

const HomeMenuHeader: React.FC<HomeMenuHeaderProps> = (props) => {

    const loginInfo = useAppSelector(loginStatus);

    let navigate = useNavigate();
    const moveToLogin = () =>{ 
        navigate(`/`);
    }

    const moveToRegistBuilding = () => {
        navigate(`/regist`);
    }

    if (!loginInfo.isLoginSuccess) {
        moveToLogin();
    }

    return (
        <div className={styles.home_head_container}>
            <div className={styles.home_head_multi_box_div}>
                <MenuButton title={props.serviceName} disabled={true} onClick={() => {}} />
            </div>
            <div className={styles.home_head_multi_box_empty_div}/>
            <div className={styles.home_head_multi_box_div}>
                <MenuButton title={props.userName} disabled={true} onClick={() => {}} />
                <MenuButton title={"建物検索"} disabled={true} onClick={() => {}} />
            </div>
            <div className={styles.home_head_multi_box_div}>
                <MenuButton title={"ログアウト"} disabled={false} onClick={() => {
                    props.onClickLogout();
                }} />
                <MenuButton title={"建物登録"} disabled={false} onClick={() => {
                    moveToRegistBuilding();
                }} />
            </div>
        </div> 
    )
}

export default HomeMenuHeader;