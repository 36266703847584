import {Action, Dispatch} from "redux";
import { BuildingRepository } from "../../domain/repository/Building";
import { BuildingInfo } from "../../model/building";
import { store } from "../../Store/Building";
import { IInputErrors, InputErrorKeys } from "../Common";
import { BuildingListErrorKeys } from "../Error/BuildingListError";

export const ActonTypes = {
    ON_COMPONENT_DID_MOUNT: "BUILDING_LIST_ON_COMPONENT_DID_MOUNT",
    CLEAR_LIST: "BUILDING_LIST_CLEAR_LIST",
    CLEAR_DELETE_INFO: "CLEAR_DELETE_INFO",
    SUCESS_DELETE_BUILDING: "SUCESS_DELETE_BUILDING",
    ON_DELETE_BUILDING: "BUILDING_LIST_ON_DELETE_BUILDING",
    FAIURE_DELETE_BUILDING: "FAIURE_DELETE_BUILDING",
    FAIURE_BUILDING_LIST: "FAIURE_BUILDING_LIST",
}

export interface IBuildingListAction extends Action {
    buildingList: BuildingInfo[],
    errors: IInputErrors,
    selectedId: string,
    selectedName: string
}

export const onComponentDidMount = () => {
    return async(dispatch: Dispatch, getState: typeof store.getState) => {

        let loginState = getState().login;
        let authToken = loginState.sessionInfo?.idToken ?? "";
        let buildingRepository = new BuildingRepository();
        let response = await buildingRepository.getBuildingInfos(authToken);
        console.log("building list -> onComponentDidMount response status ==>" + response.status );
        let list = response.datas.data;

        let errors: IInputErrors = {}
        if (response.error === InputErrorKeys.API_ERROR || response.error === InputErrorKeys.ETC_ERROR) {
                errors[response.error] = true;
                dispatch({
                    type: ActonTypes.FAIURE_BUILDING_LIST,
                    errors: errors  
                })
                return;
        }
        if (list.length > 0) {
            dispatch({
                type: ActonTypes.ON_COMPONENT_DID_MOUNT,
                buildingList: list
            })
            return;
        }
        errors[BuildingListErrorKeys.BUIDING_DATA_NOT_FOUND] = true;
        dispatch({
            type: ActonTypes.FAIURE_BUILDING_LIST,
            errors: errors  
        })
    }
}

export const clearList = () => ({
    type: ActonTypes.CLEAR_LIST
})

export const clearDeleteInfoOnly = () => ({
    type: ActonTypes.CLEAR_DELETE_INFO
})

export const onDeleteBuilding = (id: string, name: string) => {
    console.log(" onDeleteBuilding id: " + id + "name:" + name);
    return async(dispatch: Dispatch, getState: typeof store.getState) => {
        dispatch({
            type: ActonTypes.ON_DELETE_BUILDING,
            selectedId: id,
            selectedName: name
        })

        let loginState = getState().login;
        let authToken = loginState.sessionInfo?.idToken ?? "";
        let buildingRepository = new BuildingRepository();
        let response = await buildingRepository.deleteBuildingInfo(authToken, id);
        console.log(" onDeleteBuilding response status ==>" + response.status );

        let errors: IInputErrors = {}
        if (response.error === InputErrorKeys.API_ERROR || response.error === InputErrorKeys.ETC_ERROR) {
                errors[response.error] = true;
                dispatch({
                    type: ActonTypes.FAIURE_DELETE_BUILDING,
                    errors: errors  
                })
                return;
        }
        if (response.isSuccess) {
            dispatch({
                type: ActonTypes.SUCESS_DELETE_BUILDING,
            })
            return;
        }
        errors[BuildingListErrorKeys.BUIDING_DELETED_FAILED] = true;
        dispatch({
            type: ActonTypes.FAIURE_DELETE_BUILDING,
            errors: errors  
        })
    }
}