import { BuidingListInfo } from "../../model/building";
import ApiRequest from "../Api";

export interface RegistResponse {
    status: number,
    isSuccess: boolean,
    error?: any
}

export interface BuildingListResponse {
    status: number,
    datas: BuidingListInfo,
    error?: any
}

const apiDomain = "https://fworld-dev.shinatomo.work";

export class BuildingRepository {
    public async sendBuildingInfo(name: string,
                                  ownerName: string,
                                  tel: string,
                                  isAvailable: boolean,
                                  authToken: string): Promise<RegistResponse> {
        let url = apiDomain + "/api/rentBuilding" ;
        let params = {
            name: name,
            ownerName: ownerName,
            tel: tel,
            status: true,
            isAvailableRent: isAvailable
        }
        let response = await new ApiRequest().post(url, params, authToken);
        return {
            status: response.status, 
            isSuccess: true,
            error: response.error
        }
    }

    public async getBuildingInfos(authToken: string): Promise<BuildingListResponse> {
        let url = apiDomain + "/api/rentBuilding/" ;
        let response = await new ApiRequest().get(url, authToken);
        return {
            status: response.status, 
            datas: response.datas as BuidingListInfo,
            error: response.error
        }
    }

    public async deleteBuildingInfo( authToken: string, id: string): Promise<RegistResponse> {
        let url = apiDomain + "/api/rentBuilding/" + id ;
        let response = await new ApiRequest().delete(url, authToken);
        return {
            status: response.status, 
            isSuccess: true,
            error: response.error
        }
    }
}