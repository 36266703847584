
import { InputErrorKeys } from "../../Actions/Common";

interface RequstParams {
    [key: string]: any;
}

interface ApiResponse {
    status: number,
    datas?: any,
    error?: any
}

const responsSuccessCode = 200;

class ApiRequest {
   
    getHeaders(authToken?: string) {
        let token = authToken ?? "";
        if (token.length <= 0) {
            throw new Error("authToken not found");
        } 
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    }

    async get(url: string, authToken?: string, params?: RequstParams, ): Promise<ApiResponse> {
        const method = "GET";

        if (params != null) {
            const searchParams = new URLSearchParams();
            Object.keys(params).forEach(key => searchParams.append(key, params[key]));
            url = url + '?' + searchParams.toString();
        }
        try {
            let headers = this.getHeaders(authToken);
            const response = await fetch(url, { method: method, headers: headers});
            if(!response.ok) {
                throw new Error("server error status:" + response.status)
            }
            if (response.status === responsSuccessCode) {
                let datas = JSON.parse(await response.text());
                console.debug(JSON.stringify(datas));
                return { status: response.status, datas: datas, error: null }
            }
            return { status: response.status, datas: null, error: null }
        } catch(error) {
            console.log("post error ==> " + error );
            return { status: 500, datas: null, error: InputErrorKeys.API_ERROR }
        }
    }

    async post (url: string, params?: any, authToken?: string ): Promise<ApiResponse> {
        const method = "POST";
        let body = "";
        if (params != null) {
            body = JSON.stringify(params);
        }
        try {
            let headers = this.getHeaders(authToken);
            const response = await fetch(url, { method: method, headers: headers, body: body});
            if(!response.ok) {
                throw new Error("server error status:" + response.status)
            }
            if (response.status === responsSuccessCode) {
                let datas = JSON.parse(await response.text());
                console.debug(JSON.stringify(datas));
                return { status: response.status, datas: datas, error: null }
            }
            return { status: response.status, datas: null, error: null}
        } catch(error) {
            console.log("post error ==> " + error );
            return { status: 500, datas: null, error: InputErrorKeys.API_ERROR }
        } 
    }

    async login (url: string, params?: any): Promise<ApiResponse> {
        const method = "POST";
        let body = "";
        if (params != null) {
            body = JSON.stringify(params);
        }
        let headers =  {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        try {
            const response = await fetch(url, { method: method, headers: headers, body: body});
            if(!response.ok) {
                throw new Error("server error status:" + response.status)
            }
            if (response.status === responsSuccessCode) {
                let datas = JSON.parse(await response.text());
                console.debug(JSON.stringify(datas));
                return { status: response.status, datas: datas, error: null }
            }
            return { status: response.status, datas: null, error: null}
        } catch(error) {
            console.log("post error ==> " + error );
            return { status: 500, datas: null, error: InputErrorKeys.API_ERROR }
        } 
    }

    async delete (url: string,  authToken?: string ): Promise<ApiResponse> {
        const method = "DELETE";
        try {
            let headers = this.getHeaders(authToken);
            const response = await fetch(url, { method: method, headers: headers});
            if(!response.ok) {
                throw new Error("server error status:" + response.status)
            }
            return { status: response.status, datas: null, error: null}
        } catch(error) {
            console.log("post error ==> " + error );
            return { status: 500, datas: null, error: InputErrorKeys.API_ERROR }
        }
    }
}

export default ApiRequest;