import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import { Login } from "../../Reducers/Login";
import { RegistBuilding } from "../../Reducers/RegistBuilding";
import { ProgressDialog } from "../../Reducers/ProgressDialog";
import { BuildingList } from "../../Reducers/BuildingList";

const reducers = combineReducers({
    login: Login,
    registBuilding: RegistBuilding,
    progressDialog: ProgressDialog,
    buildingList: BuildingList,
    
});

export const store = configureStore({ reducer: reducers });
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;