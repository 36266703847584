import { ActonTypes, IBuildingListAction } from "../../Actions/BuildingList";
import { BuildingInfo } from "../../model/building";
import { IInputErrors } from "../../Actions/Common";
import { RootState } from "../../Store/Building";

export type BuidingListState = {
    data: BuildingInfo[],
    errors?: IInputErrors,
    selectedId: string,
    selectedName: string,
    isDeleteSuccess: boolean
}

export const InitatialBuidingListState: BuidingListState = {
    data: [],
    selectedId: "",
    selectedName: "",
    isDeleteSuccess: false,
}

export const buildingListStatus = (state: RootState) => state.buildingList;

export const BuildingList = (state: BuidingListState = InitatialBuidingListState, action: IBuildingListAction) => {
    //console.log("reducer BuildingList action.type:" + action.type);
    switch(action.type) {
        case ActonTypes.ON_COMPONENT_DID_MOUNT: {
            state = {
                ...InitatialBuidingListState,
                data: action.buildingList
            }
            break
        }
        case ActonTypes.FAIURE_BUILDING_LIST: {
            state = {
                ...InitatialBuidingListState,
                errors: action.errors
            }
            break;
        }
        case ActonTypes.CLEAR_LIST: {
            state = {
                ...InitatialBuidingListState,
            }
            break;
        }
        case ActonTypes.CLEAR_DELETE_INFO: {
            state = {
                ...state,
                selectedId: "",
                selectedName: "",
                isDeleteSuccess: false,
            }
            break;
        }
        case ActonTypes.FAIURE_DELETE_BUILDING: {
            state = {
                ...state,
                errors: action.errors
            }
            break;
        }
        case ActonTypes.SUCESS_DELETE_BUILDING: {
            state = {
                ...state,
                isDeleteSuccess: true
            }
            break;
        }
        case ActonTypes.ON_DELETE_BUILDING: {
            state = {
                ...state,
                selectedId: action.selectedId,
                selectedName: action.selectedName
            }
            break;
        }
        default: break;
    }
    //console.log("after state:" + JSON.stringify(state))
    return state;
}