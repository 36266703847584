import {Action, Dispatch} from "redux";
import { LoginRepository } from "../../domain/repository/Login";
import { SessionInfo } from "../../Reducers/Login";
import { store } from "../../Store/Building";
import { IInputErrors, InputErrorKeys } from "../Common/";
import { checkLoginInputValue } from "../Error/LoginError";

export const ActonTypes = {
    ON_CHANGE_USER_ID: "LOGIN_ON_CHANGE_USER_ID",
    ON_CHANGE_PASSWORD: "LOGIN_ON_CHANGE_PASSWORD",
    ON_FAIURE_LOGIN: "LOGIN_ON_FAIURE_LOGIN",
    COMPLETE_LOGIN: "LOGIN_COMPLETE_LOGIN",
    ON_COMPONENT_DID_MOUNT: "LOGIN_ON_COMPONENT_DID_MOUNT",
    ON_LOGOUT: "ON_LOGOUT"
}

export interface ILoginAction extends Action {
    userId: string,
    password: string,
    errors: IInputErrors,
    sessionInfo: SessionInfo
}

export const onComponentDidMount = () => ({
    type: ActonTypes.ON_COMPONENT_DID_MOUNT,
});

export const onClickLogin = () => {
    return async(dispatch: Dispatch, getState: typeof store.getState) => {
 
        let state = getState().login
        let errors = checkLoginInputValue(state)
        if (Object.keys(errors).length > 0) {
            dispatch({
                type: ActonTypes.ON_FAIURE_LOGIN,
                errors: errors
            })
            return;
        }

        let loginRepository = new LoginRepository();
        let response = await loginRepository.getLoginSession(state.userId, state.password);
        console.log("onClickLogin response sessionInfo email ==>" + response.sessionInfo?.email );
        console.log("onClickLogin response status ==>" + response.status );
        let idToken = response.sessionInfo?.idToken ?? "";
        if (response.error === InputErrorKeys.API_ERROR || response.error === InputErrorKeys.ETC_ERROR) {
            errors[response.error] = true;
            dispatch({
                type: ActonTypes.ON_FAIURE_LOGIN,
                errors: errors  
            })
            return;
        }
        if (idToken.length > 0 ) {
            dispatch({
                type: ActonTypes.COMPLETE_LOGIN,
                sessionInfo: response.sessionInfo
            })
            return;
         }
        errors[InputErrorKeys.ETC_ERROR] = true;
        dispatch({
            type: ActonTypes.ON_FAIURE_LOGIN,
            errors: errors  
        })
         
    }
}

export const onChangeUserId = (userId: string) => ({
        type: ActonTypes.ON_CHANGE_USER_ID,
        userId: userId
});


export const onChangePassword = (password: string) => ({
    type: ActonTypes.ON_CHANGE_PASSWORD,
    password: password
});

export const onClickLogout = () => ({
    type: ActonTypes.ON_LOGOUT,
});