import React, { useEffect } from "react";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import * as registError from "../../../../Actions/Error/RegistBuildingError";
import TextLoginError from "../../../Atoms/Text/LoginError";
import { registBuilding } from "../../../../Reducers/RegistBuilding";
import { useAppSelector } from "../../../../hooks";
import InputRegist from "../../../Molecules/Input/Regist";
import TextHomeTitle from "../../../Atoms/Text/HomeTitle";
import CheckBoxRegist from "../../../Molecules/CheckBox/Regist";
import MenuButton from "../../../Atoms/Button/Menu";
import { showCompletedAlert } from '../../../Molecules/Alert/alert';

interface MainContentProps {
    onClickRegist: Function,
    onChangeName: Function,
    onChangeOwnerName: Function,
    onChangeAddress: Function,
    onChangeTelNum: Function,
    onChangeAvailableCheck: Function,
    onComponentDidMount: Function,
    showProgressDialog: Function,
    closeProgressDialog: Function,
    clearInfo: Function,
}

const RegistBuildingMainContent: React.FC<MainContentProps> = (props) => {

    let title = "管理画面 - 建物の登録";
    const registInfo = useAppSelector(registBuilding);
    let navigate = useNavigate(); 
    const moveToHome = () => { 
        navigate(`/home`);
    }

    const componentDidMount = useEffect(() => {
        props.onComponentDidMount();
    }, []);

    if (registInfo.isRegistSuccess) {
        props.clearInfo();
        props.closeProgressDialog();
        let alertTitle = "建物の登録";
        let message = "登録しました。ホーム画面に遷移します。"
        showCompletedAlert(alertTitle, message, moveToHome);
    }
 
    const hasNameError = registError.errorNameTitle(registInfo).length > 0;
    const hasOwnerNameError = registError.errorOwnerNameTitle(registInfo).length > 0;
    const hasResultError = registError.errorRegisteResultTitle(registInfo).length > 0;

    if (hasNameError || hasOwnerNameError || hasResultError) {
        props.closeProgressDialog();
    }

    return (
        <>
        <div className={styles.regist_title_div}>
            <TextHomeTitle title={title} />
        </div>
        <div className={styles.regist_container}>
            <div className={styles.regist_contents}>
                <InputRegist title={"名称"} onChange={(value: string) => {props.onChangeName(value)}}/>
                <div style={{ display: hasNameError ? '' : 'none' }}>
                    <TextLoginError title={registError.errorNameTitle(registInfo)}/>
                </div>
                <InputRegist title={"オーナ"} onChange={(value: string) => {props.onChangeOwnerName(value)}}/>
                <div style={{ display: hasOwnerNameError ? '' : 'none' }}>
                    <TextLoginError title={registError.errorOwnerNameTitle(registInfo)}/>
                </div>
                <InputRegist title={"住所"} onChange={(value: string) => {props.onChangeAddress(value)}}/>
                <InputRegist title={"電話番号"} onChange={(value: string) => {props.onChangeTelNum(value)}}/>
                <CheckBoxRegist isChecked={registInfo.isAvailable} onChangeAvailableCheck={props.onChangeAvailableCheck}/>
                <div style={{ display: hasResultError ? '' : 'none' }}>
                    <TextLoginError title={registError.errorRegisteResultTitle(registInfo)}/>
                </div>
                <div className={styles.regist_button_div}>
                    <MenuButton title={"登録"} disabled={false} onClick={() => {
                        props.showProgressDialog();
                        props.onClickRegist();
                    }} />
                    <MenuButton title={"戻る"} disabled={false} onClick={() => {
                        moveToHome();
                    }} />
                </div>
            </div>
        </div>
        </>
    );
}

export default RegistBuildingMainContent;