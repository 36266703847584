import React from "react";
import styles from "./index.module.css";

type Props = {
    onClick: Function
    title: string,
    disabled: boolean
}

const MenuButton: React.FC<Props> = (props) => {
    return (
        <button className={styles.menu_button} onClick={() => {props.onClick()}} disabled={props.disabled}>
            <span className={styles.menu_button_title}>{props.title}</span>
        </button>
    )
}

export default MenuButton;